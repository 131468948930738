import React from "react"
import { GatsbyLocation } from "local-types"
import SEO from "../../components/Seo/Seo"
import styled from "styled-components"
import { useSiteMetadata } from "../../hooks"

const standardMetadata = {
  Title: "Flipbase",
  Keywords: ["Flipbase"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

interface InhouseRecruitmentEngProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const InhouseRecruitmentEng: React.FunctionComponent<
  InhouseRecruitmentEngProps
> = ({ location }): React.ReactElement => {
  return (
    <Container>
      <SEO
        title={standardMetadata.Title}
        description={standardMetadata.Description}
        keywords={standardMetadata.Keywords}
        image={standardMetadata.Image}
      />
      <iframe
        src="https://slides.com/bramtierie/flipbase-integrated-asynchronous-video-interaction-inhouse-recruitment-eng/embed"
        className="responsive-iframe"
        scrolling="no"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 55.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export default InhouseRecruitmentEng
